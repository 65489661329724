<template>
  <div class="error-layout">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'ErrorLayout',
};
</script>

<style lang="scss">
body:has(.error-layout) {
  background-color: #272730;
}
</style>
